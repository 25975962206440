import React, { useState, useEffect } from 'react';

// projects image
import htmlIMG from "../img/knowledge/html.svg";
import cssIMG from "../img/knowledge/css.png";
import javascriptIMG from "../img/knowledge/javascript.svg";
import typescriptIMG from "../img/knowledge/typescript.svg";
import leafletIMG from "../img/knowledge/leaflet.png";
import googleDevIMG from "../img/knowledge/google-dev.png";
import jqueryIMG from "../img/knowledge/jquery.png";
import bootstrapIMG from "../img/knowledge/bootstrap.svg";
import antDesignIMG from "../img/knowledge/ant-design.png";
import responsiveDesignIMG from "../img/knowledge/responsive-web-design.png";
import pwaIMG from "../img/knowledge/pwa.png";
import webpackIMG from "../img/knowledge/webpack.svg";
import socketioIMG from "../img/knowledge/socket-io.svg";
import mongodbIMG from "../img/knowledge/mongodb.svg";
import expressIMG from "../img/knowledge/express.png";
import reactIMG from "../img/knowledge/react.svg";
import nodeIMG from "../img/knowledge/nodejs.svg";
import nextIMG from "../img/knowledge/nextjs.png";
import ejsIMG from "../img/knowledge/ejs.png";
import uiuxIMG from "../img/knowledge/ui-ux.png";
import serversiderenderingIMG from "../img/knowledge/server-side-rendering.png";
import sequelizeIMG from "../img/knowledge/sequelize.png";
import postgresqlIMG from "../img/knowledge/postgresql.svg";
import mysqlIMG from "../img/knowledge/mysql.png";
import phpIMG from "../img/knowledge/php.svg";
import gitIMG from "../img/knowledge/git.png";
import githubIMG from "../img/knowledge/github.svg";
import visualstudiocodeIMG from "../img/knowledge/visual-studio-code.svg";
import postmanIMG from "../img/knowledge/postman.png";
import cloudandserverIMG from "../img/knowledge/cloud-server.png";
import awss3IMG from "../img/knowledge/aws-s3.png";
import herokuIMG from "../img/knowledge/heroku.png";
import netlifyIMG from "../img/knowledge/netlify.png";
import namecheapIMG from "../img/knowledge/namecheap.png";
import anydeskIMG from "../img/knowledge/anydesk.png";
import bitbucketIMG from "../img/knowledge/bitbucket.png";

export default function Knowledge() {

    // craete initial state
    const [knowledge, setKnowledge] = useState([]);

    useEffect(() => {
        // organize data
        const knowledgesData = [
            {
                technologyIMG: htmlIMG,
                technology: 'HTML',
                customCss: {
                    width: '22px'
                }
            },
            {
                technologyIMG: cssIMG,
                technology: 'CSS',
                customCss: {
                    width: '22px'
                }
            },
            {
                technologyIMG: javascriptIMG,
                technology: 'Javascript'
            },
            {
                technologyIMG: typescriptIMG,
                technology: 'TypeScript'
            },
            {
                technologyIMG: leafletIMG,
                technology: 'Leaftlet Maps',
                customCss: {
                    width: '30px'
                }
            },
            {
                technologyIMG: googleDevIMG,
                technology: 'Google Maps APIs',
            },
            {
                technologyIMG: responsiveDesignIMG,
                technology: 'Responsive Design',
                customCss: {
                    width: '85px'
                }
            },
            {
                technologyIMG: pwaIMG,
                technology: 'Pwa',
                customCss: {
                    width: '45px'
                }
            },
            {
                technologyIMG: jqueryIMG,
                technology: 'JQuery',
            },
            {
                technologyIMG: bootstrapIMG,
                technology: 'Bootstrap',
                customCss: {
                    width: '32px'
                }
            },
            {
                technologyIMG: antDesignIMG,
                technology: 'Ant Design',
                customCss: {
                    width: '28px'
                }
            },
            {
                technologyIMG: webpackIMG,
                technology: 'Webpack',
                customCss: {
                    width: '30px'
                }
            },
            {
                technologyIMG: socketioIMG,
                technology: 'Socket.io',
                customCss: {
                    width: '30px'
                }
            },
            {
                technologyIMG: mongodbIMG,
                technology: 'MongoDB',
                customCss: {
                    width: '15px'
                }
            },
            {
                technologyIMG: expressIMG,
                technology: 'Express',
                customCss: {
                    width: '35px'
                }
            },
            {
                technologyIMG: reactIMG,
                technology: 'React',
                customCss: {
                    width: '30px'
                }
            },
            {
                technologyIMG: nextIMG,
                technology: 'NextJs',
                customCss: {
                    width: '30px'
                }
            },
            {
                technologyIMG: nodeIMG,
                technology: 'Nodejs'
            },
            {
                technologyIMG: ejsIMG,
                technology: 'EJS',
                customCss: {
                    width: '35px'
                }
            },
            {
                technologyIMG: uiuxIMG,
                technology: 'UX / UI Design',
                customCss: {
                    width: '32px'
                }
            },
            {
                technologyIMG: serversiderenderingIMG,
                technology: 'Server Side Rendering',
                customCss: {
                    width: '35px'
                }
            },
            {
                technologyIMG: sequelizeIMG,
                technology: 'Sequelize',
                customCss: {
                    width: '30px'
                }
            },
            {
                technologyIMG: postgresqlIMG,
                technology: 'PostgreSQL',
                customCss: {
                    width: '27px'
                }
            },
            {
                technologyIMG: mysqlIMG,
                technology: 'MySQL',
                customCss: {
                    width: '32px'
                }
            },
            {
                technologyIMG: phpIMG,
                technology: 'Php',
                customCss: {
                    width: '45px'
                }
            },
            {
                technologyIMG: gitIMG,
                technology: 'GIT'
            },
            {
                technologyIMG: githubIMG,
                technology: 'Github',
                customCss: {
                    width: '30px'
                }
            },
            {
                technologyIMG: bitbucketIMG,
                technology: 'BitBucket',
                customCss: {
                    width: '30px'
                }
            },
            {
                technologyIMG: visualstudiocodeIMG,
                technology: 'Visual Studio Code'
            },
            {
                technologyIMG: postmanIMG,
                technology: 'PostMan'
            },
            {
                technologyIMG: anydeskIMG,
                technology: 'AnyDesk'
            },
            {
                technologyIMG: cloudandserverIMG,
                technology: 'Cloud & Servers',
                customCss: {
                    width: '35px'
                }
            },
            {
                technologyIMG: awss3IMG,
                technology: 'AWS S3'
            },
            {
                technologyIMG: herokuIMG,
                technology: 'Heroku'
            },
            {
                technologyIMG: namecheapIMG,
                technology: 'Namecheap',
                customCss: {
                    width: '40px'
                }
            },
            {
                technologyIMG: netlifyIMG,
                technology: 'Netlify',
            },
        ];
        // create state and add Knowledge
        setKnowledge(knowledgesData);
    }, [])

    // generate templates for each technology
    const techElements = knowledge.map((tech, index) => (
                            <div key={index} className='card-technology rounded' data-aos="zoom-in" data-aos-offset="180" data-aos-duration="1500">
                                <img src={tech.technologyIMG} style={tech.customCss} alt="tech logo"/>
                                <span className='ps-2'>
                                    {tech.technology}
                                </span>
                            </div>
                        ));

    // render components
    return (
        <div id='knowledge'>
            <h2 className='text-center mb-5' data-aos="fade-right">
                My Knowledge
            </h2>
            <div className="p-0 container-lg knowledge-list">
                {techElements}
            </div>
        </div>
    );
}