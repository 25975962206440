import React, {} from 'react';

// Font Families
import './nunito.css';
import './anton-regular.css';

// Main css
import './App.css';

// Components
import Header from "./components/Header";
import Repositories from "./components/Repositories";
import Knowledge from "./components/Knowledge";
import About from "./components/About";

// Url img
import platziIMG from "./img/platzi.webp";
import intagramIMG from "./img/instagram.png";
import linkedinIMG from "./img/linkedin.png";
import githubIMG from "./img/github.png";

function App() {  
  return (
    <div className="App">
        <Header></Header>
        <main>
          <Repositories></Repositories>
          <Knowledge></Knowledge>
          <About></About>
        </main>
        <footer>
          <div className='d-flex justify-content-center align-items-center pt-5 pb-4'>
            <a href='https://platzi.com/p/AdrielMinyetyG._yetto_/' target={'_blank'} rel="noreferrer" className='px-2 px-sm-4'>
              <small className='d-flex flex-column align-items-center'>
                <img src={platziIMG} style={{cursor: 'pointer'}} className='me-1' alt="platzi logo"/>
                <span>
                  Courses
                </span>
              </small>
            </a>
            <a href='https://instagram.com/_yetto_' target={'_blank'} rel="noreferrer" className='px-2 px-sm-4'>
              <small className='d-flex flex-column align-items-center'>
                <img src={intagramIMG} style={{cursor: 'pointer'}} alt="instagram logo" />
                <span>
                  Instagram
                </span>
              </small>
            </a>
            <a href='https://www.linkedin.com/in/adrielminyety/' target={'_blank'} rel="noreferrer" className='px-2 px-sm-4'>
              <small className='d-flex flex-column align-items-center'>
                <img src={linkedinIMG} style={{cursor: 'pointer'}} alt="linkedin logo" />
                  LinkedIn
              </small>
            </a>
            <a href='https://github.com/AdrielMinyety' target={'_blank'} rel="noreferrer" className='px-2 px-sm-4'>
              <small className='d-flex flex-column align-items-center'>
                <img className='githubIMG' src={githubIMG} style={{cursor: 'pointer'}} alt="github logo" />
                  Github
              </small>
            </a>
          </div>
          <h6 className='bg-dark stiky-bottom text-center text-light mb-0 py-2'>
            Web made with ♥ by Adriel Minyety &copy;
          </h6>
        </footer>
    </div>
  );
}

export default App;
