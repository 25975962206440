import React, { useState, useEffect } from 'react';

// projects image
import ugeritIMG from "../img/repositories/ugerit-stats-desktop.png";
import reactBetweenWorldsIMG from "../img/repositories/react-between-worlds.png";
import reactPixybayIMG from "../img/repositories/react-pixybay.png";
import reactCarFinderIMG from "../img/repositories/react-car-finder.png";
import reactMoneyManagerIMG from "../img/repositories/react-money-manager.png";
import reactScheduleManagerIMG from "../img/repositories/react-schedule-manager.png";
import jsShiftManagerIMG from "../img/repositories/js-shift-manager.png";
import jsGameRunnerIMG from "../img/repositories/js-game-runner.png";

export default function Repositories() {

    // -INITIAL STATES -----------------------------------------------------
    // create initial state
    const [repositories, setRepositories] = useState([])

    // state to go for each repository in the viewer
    const [viewerRepositories, setViewerRepositories] = useState({
        currectIndex: 0,
        maxRepositories: 0,
        viewerShowed: window.innerWidth > 575? false : true,
        screenInnerWidth: window.innerWidth
    });
    // ---------------------------------------------------------------------

    useEffect(() => {
        // organize data
        const repositoriesData = [
            {
                repositoryIMG: ugeritIMG,
                name: 'UGERIT.COM',
                description: 'This is an online platform with user-friendly tools for business management.',
                url: 'https://ugerit.com',
                urlCode: null
            },
            {
                repositoryIMG: reactPixybayIMG,
                name: 'React - Pixybay Api',
                description: 'It is a website where with just a word you find free images from pixabay.com',
                url: 'https://adrielminyety.github.io/React-ImgFinder/',
                urlCode: 'https://github.com/AdrielMinyety/React-ImgFinder'
            },
            {
                repositoryIMG: reactBetweenWorldsIMG,
                name: 'React Between Worlds',
                description: "It's a website that calculates your weight in different locations in the universe.",
                url: 'https://adrielminyety.github.io/React-EntreMundos/',
                urlCode: 'https://github.com/AdrielMinyety/React-EntreMundos'
            },
            {
                repositoryIMG: reactCarFinderIMG,
                name: 'Car Filter With JS',
                description: "It's a JavaScript website to find your car with iterators & higher-order functions.",
                url: 'https://adrielminyety.github.io/CarsFinder/',
                urlCode: 'https://github.com/AdrielMinyety/CarsFinder'
            },
            {
                repositoryIMG: reactMoneyManagerIMG,
                name: 'React Money Expenses',
                description: "It's a website to manage your expenses and track where you spend your money.",
                url: 'https://adrielminyety.github.io/React-ExpenseManager/',
                urlCode: 'https://github.com/AdrielMinyety/React-ExpenseManager'
            },
            {
                repositoryIMG: reactScheduleManagerIMG,
                name: 'React Schedule Manager',
                description: "Web to schedule appoitments dates for people, to give them a service & get in contact.",
                url: 'https://adrielminyety.github.io/React-AgendApp/',
                urlCode: 'https://github.com/AdrielMinyety/React-AgendApp'
            },
            {
                repositoryIMG: jsShiftManagerIMG,
                name: 'Shifts Manager with JS',
                description: "It's a website for scheduling shifts and checking work schedules for the current day.",
                url: 'https://adrielminyety.github.io/AgendaSemanal-ES6/',
                urlCode: 'https://github.com/AdrielMinyety/AgendaSemanal-ES6'
            },
            {
                repositoryIMG: jsGameRunnerIMG,
                name: 'Game Runner with JS',
                description: "It's a Javascript game where you run, jump obstacles, and try to go as far as possible.",
                url: 'https://adrielminyety.github.io/Game-JavaScriptES6-Runner/',
                urlCode: 'https://github.com/AdrielMinyety/Game-JavaScriptES6-Runner'
            },
        ];
        // create state and add repositories
        setRepositories(repositoriesData);
        // update repositories length
        setViewerRepositories(prevViewerRepositories => ({
            ...prevViewerRepositories,
            maxRepositories: repositoriesData.length
        }));
    }, []);

    // -FUNCTIONS -----------------------------------------------------------
    const detectIfNextOrBackRepo = ev => {
        // if another element was clicked, do not do anything 
        if(ev.target.id !== 'back' && ev.target.id !== 'next') 
            return {
                ...viewerRepositories
            }
        // create a loop counter, between 0-7
        setViewerRepositories(prevViewerRepositories => {
            let newCurrentIndex;
            // detect if goes back
            if (ev.target.id === 'back') {
              newCurrentIndex = prevViewerRepositories.currectIndex - 1;
              if (newCurrentIndex < 0) {
                newCurrentIndex = prevViewerRepositories.maxRepositories - 1;
              }
            // or to the next
            } else if (ev.target.id === 'next') {
              newCurrentIndex = prevViewerRepositories.currectIndex + 1;
              if (newCurrentIndex >= prevViewerRepositories.maxRepositories) {
                newCurrentIndex = 0;
              }
            }
            return {
              ...prevViewerRepositories,
              currectIndex: newCurrentIndex
            };
        });
    }
    const detectResizingScreen = () =>
        // update state when resizing
        setViewerRepositories({
            ...viewerRepositories,
            viewerShowed: window.innerWidth > 575? false : true
    });
    // ----------------------------------------------------------------------
    // -EVENTLISTENER ------------------------------------------------------
    // Detect when user resize the window or screen
    let timeout;
    window.addEventListener('resize', () => {
        // Avoid multiple run of code
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            // just run once per resize
            detectResizingScreen();
            // if width changed reload page
            if(viewerRepositories.screenInnerWidth !== window.innerWidth) window.location.reload();
        }, 200);
    });
    // ----------------------------------------------------------------------
    
    // generate templates for each repository
    const repoElements = repositories.map((repo, index) => (
                            <div 
                                key={index} 
                                data-aos={index === viewerRepositories.currectIndex && viewerRepositories.viewerShowed? '' : "zoom-in" } 
                                className={index === viewerRepositories.currectIndex? "d-sm-flex col-12 col-sm-6 col-md-4 col-lg-3 px-0 px-sm-1 py-3 py-sm-1 h-100" : "d-none d-sm-flex col-12 col-sm-6 col-md-4 col-lg-3 px-0 px-sm-1 py-3 py-sm-1 h-100"}>
                                
                                <div className="shadow-sm border card-repository">
                                    <img src={repo.repositoryIMG} alt="ugerit screen" />
                                    <div className='card-repository-info'>
                                        <p className='m-0'>{repo.name}</p>
                                        <small>{repo.description}</small>
                                        <div className='d-flex justify-content-between'>
                                            <a href={repo.url} target={'_blank'} rel="noreferrer">
                                                Check it out
                                            </a>
                                            <a href={repo.urlCode === null? repo.url : repo.urlCode} target={'_blank'} rel="noreferrer" className='text-light'>
                                                {repo.urlCode === null? 'Private Code' : 'Github'}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ));

    // render components
    return (
        <div className='container-fluid container-lg my-5 py-5 position-relative' id='repositories'>
            <h2 className='text-center position-relative' data-aos="fade-right" style={{zIndex: '10'}}>
                SOME REPOSITORIES
            </h2>
            <div className='w-100 position-relative'>
                <div className="mt-3 mt-lg-4 mt-xl-5 w-100 mx-auto row repositories-list">
                    {repoElements}
                </div>
                <small data-aos="fade-up" data-aos-offset="250" className='d-none d-sm-block mt-4 p-0 ps-sm-1 text-center position-relative' style={{zIndex:1}}>
                    <a href="https://github.com/AdrielMinyety?tab=repositories" className='fw-bold' target={'_blank'} rel="noreferrer">
                        See more repositories ( the public ones )
                    </a>
                </small>
                <div data-aos="fade-up" data-aos-offset="180" id='view-repository-manual' onClick={detectIfNextOrBackRepo} className='position-absolute w-100 d-flex d-sm-none justify-content-between align-items-center'>
                    <span id='back' className='badge bg-dark fs-6'>Back</span>
                    <small className='d-block p-0 ps-sm-1 text-center'>
                        <a href="https://github.com/AdrielMinyety?tab=repositories" target={'_blank'} rel="noreferrer">
                            See more repositories
                            <br /> 
                            ( the public ones )
                        </a>
                    </small>
                    <span id='next' className='badge bg-dark fs-6'>Next</span>
                </div>
            </div>
            <span className='triangle'></span>
        </div>
    );
}