import React, {} from 'react';

// get Adriel's image
import adrielIMG from "../img/foto-avatar-adriel.JPG";

export default function Header() {

    // render components
    return (
        <header className='position-relative'>
            <span className='triangle'></span>
            <span className='triangle'></span>
            <div className='links d-flex justify-content-evenly'>
                <a href='#about' className='p-0'>
                    <small className='contact'>
                        Contact
                    </small>
                </a>
                <a href='#repositories'>
                    <small>
                    Repositories
                    </small>
                </a>
                <a href='#knowledge'>
                    <small>
                    Knowledge
                    </small>
                </a>
                <a href='#about'>
                    <small>
                    About
                    </small>
                </a>
            </div>
            <div id='greetings' className='d-flex flex-column flex-sm-row-reverse align-items-center justify-content-evenly'>
                <div className='position-relative'>
                    <div id='avatar-bg'></div>
                    <img id='adriel-avatar' src={adrielIMG} alt='adriels avatar' data-aos="zoom-in"/>
                </div>
                <div className='d-flex flex-column justify-content-sm-around justify-content-lg-between'>
                    <h1 data-aos="fade-right">
                        I am 
                        <br />
                        <span className='pe-1'>
                            Adriel
                        </span>
                        <br className='d-none d-sm-block' />
                        Minyety
                    </h1>
                    <h3 data-aos="fade-up" data-aos-offset="20" className='text-start' id='adriel-title'>
                        Web Developer 
                        <span className='d-block'>
                            & Founder of ugerit.com
                        </span>
                    </h3>
                </div>
            </div>
        </header>
    );
}