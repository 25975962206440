import React, {} from 'react';

export default function About() {

    // detect when user wants to download resume (which languaje)
    const downloadResume = (ev) => {
        // depending which resume (english or spanish), send user to the pdf to download it
        let resumePDF = ev.target && ev.target.id === 'dw-english'?
                          'adriel-cv-english.pdf'  : 'adriel-cv-spanish.pdf';
        // get file's path
        const pdfPath = require('../files/'+resumePDF);
        // redirect
        window.open(pdfPath, '_blank');
    }

    // render components
    return (
        <div className='mt-5' id='about'>
            <div className='diagonal'></div>
            <div className="container-fluid container-lg mt-5 w-100 mx-auto row repositories-list">
                <h2 className='mb-4' data-aos="fade-right" data-aos-offset="300">
                    About me
                </h2>
                <p data-aos="fade-left" data-aos-offset="350">
                    I am a Web Developer, specialized in JavaScript based web technologies such as React, Js and CSS Libraries, NodeJS, MongoDB, Sequelize and PostGreSQL, interested in working remotely on scalable projects with agile methodologies such as SCRUM and LEAN. With more than 5 years of experience in freelance projects and creator of 
                    <a href="https://ugerit.com" target={'_blank'} rel="noreferrer" className='mx-1'>
                        ugerit.com, 
                    </a>
                    an online platform for business management.
                </p>

                <p data-aos="fade-right" data-aos-offset="400">
                I became interested in the tech industry while studying Information Technology, Equipments, Components & Systems, and Programming Fundamentals at a Polytechnic Institute. It dawned on me that software is a powerful tool for combining art and functional systems to solve real-world problems. I also realized that a simple idea could be transformed into a tangible product with the help of a computer, internet access, and a clear vision. Although I am primarily self-taught, I am constantly seeking to expand my knowledge and skills through platforms such as 
                    <a href="https://platzi.com" target={'_blank'} rel="noreferrer" className='ms-1'>Platzi</a>, 
                    <a href="https://udemy.com" target={'_blank'} rel="noreferrer" className='ms-1'>Udemy</a>,
                    and
                    <a href="https://en.wikipedia.org/wiki/Software_documentation" target={'_blank'} rel="noreferrer" className='ps-1'>
                        software documentation
                    </a>, as the tech industry is constantly evolving and advancing. Learning is a never-ending process in the world of tech.
                </p>

                <div className='w-100 mt-2 px-1 px-sm-3' onClick={downloadResume}>
                    <button data-aos="fade-up" data-aos-offset="80" data-aos-duration="2000" type="button" id='dw-english' className="btn btn-dark mt-2 ms-2 ms-sm-0">
                        Download Resume
                    </button>
                    <button data-aos="fade-left" data-aos-offset="80" data-aos-duration="2000" type="button" id='dw-espanish' className="btn btn-outline-dark ms-2 ms-sm-2 mt-2">
                        Descargar CV
                    </button>
                </div>
            </div>
        </div>
    );
}